<template>
  <!--
  list - объект, список элементов, должен содержать обязательный ключ title. Остальные ключи могут быть любые
  title - название элемента списка
  placeholder - необязательный, обычный placeholder
  castom - это кастомный элемент, для фильтрации. Например когда в фильтре помимо занчений из списка нужно показать Все. необязательный
  -->
  <div tabindex="1" class="custom-select">
    <div @click="ChangeStatusCustomSelect" class="custom-select__header">

      <p v-if="title" v-trim="1">{{ title }}</p>
      <p v-else v-trim="1">{{ placeholder ? placeholder : title }}</p>

      <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="11.1549" y="5.05664" width="7.00006" height="2.00002" rx="1" transform="rotate(134.83 11.1549 5.05664)" fill="#5A79A8"/>
        <rect x="2.66565" y="3.66797" width="7.00006" height="2.00002" rx="1" transform="rotate(44.8295 2.66565 3.66797)" fill="#5A79A8"/>
      </svg>

    </div>
    <transition name="fade">
      <div v-show="statusCustomSelect" class="custom-select__main">
        <!-- <p v-if="castom" @click="changeTitle(castom)">{{ castom }}</p> -->
        <input type="text" v-model="address" placeholder="Город, адрес, метро, район, ж/д, шоссе или ЖК">
         <!-- @keyup.enter="apiMemberList()" -->
        <!-- <p v-for="(item,i) in list" :key="`CustomSelect${i}`" @click="changeTitle(item.title, i)">{{ item.title }}</p> -->
        <div class="region_scroll">
            <div class="region_block">
                <ul v-for="(item, key, index) in list" :key="`item${index}`">
                  <li v-if="list[`${key}`].length">{{ key }}</li>
                  <li v-for="(area, key) in item" :key="`area${key}`" @click="changeTitle(area)">{{ area }}</li>
                </ul>
            </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'SelectAppeal',
  props: {
    list: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String
    },
    castom: {
      type: String
    },
    // title - передаем текст который нужно вывести заголовке в селект
    title: {
      type: String,
      required: false,
      default: ''
    },
    // key_name - передается в паре с title, это ключ тайтла. например {name: 'заголовок в селекте'}
    // То в title передаем 'заголовок в селекте', а в key_name передаем name
    // Это для того чтобы в полученном массиве сразу по фильтру найти строку с тайтлом и вернуть этот объект куда нужно уже
    // если запись по тайтлу в массие не найдена то вернется []
    key_name: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      statusCustomSelect: false,
      address: ''
    }
  },
  mounted () {
    if (this.title && this.key_name) {
      const value = this.list.filter(el => {
        return String(el[`${this.key_name}`]) === this.title
      })
      this.$emit('click', value) // ну или что там нужно..
    }
  },
  methods: {
    ChangeStatusCustomSelect () {
      this.statusCustomSelect = !this.statusCustomSelect
    },
    CloseDropDown () {
      this.statusCustomSelect = false
    },
    changeTitle (value, key) {
      this.CloseDropDown()
      this.$emit('InputsContent', key !== undefined ? this.list[key] : '')
      this.title = value
    }
  }
}
</script>

<style scoped lang="scss">
.custom-select {
  position: relative;
  text-align: left;

  background: #EEF4FA;
  border-radius: .19rem;

  &__header {
    font-size: 0.875rem;
    line-height: 157%;
    color: #003E78;
    background: #EEF4FA url('../../assets/icons/navigation.svg') .75rem center no-repeat;
    background-size: 1.25rem;
    border-radius: .19rem;
    padding: 0.56rem 1.25rem 0.56rem 2.5rem;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    svg {
      min-width: 1rem;
      min-height: 1rem;
    }
  }

  &__main {
    position: absolute;
    top: 140%;
    z-index: 99999;
    background: #fff;
    box-sizing: border-box;
    width: 58.25rem;
    height: 35.31rem;
    text-align: left;
    padding: 2.44rem 1.88rem;

    filter: drop-shadow(0px 33px 88px rgba(37, 70, 103, 0.15));
    input{
        width: 100%;
        height: 2.1875rem;
        padding: 0 1.25rem;
        background: #EEF4FA;
        border-radius: .19rem;

        font-weight: 600;
        font-size: .875rem;
        line-height: 1.38rem;
        color: #1F3245;
    }
    input::placeholder{
        font-weight: normal !important;
        color: #003E78;
        opacity: 0.6;
        opacity: 0.55;
    }
    input:focus{
        background: #DDEDFE;
    }
  }
}

.region_scroll{
  overflow-y: auto;
  height: calc(100% - 2.125rem - 2.5rem);
  margin: 2.125rem 0 2.5rem 0;
}
.region_block{
  column-count: 3;
  -webkit-column-count: 3;
  column-gap: 0;
  -webkit-column-gap: 0;
  margin-top: -2.125rem;

  ul{
    break-inside: avoid;
    -webkit-column-break-inside: avoid;
    padding: 5px;
    width: 15.94rem;
    margin-right: 3.562rem;
    li{
      font-size: .88rem;
      line-height: 2.19rem;
      color: #1F3245;
      cursor: pointer;
    }
    li:first-child{
      font-weight: 600;
      font-size: 1.63rem;
      line-height: 1.88rem;
      color: #306392;
      margin-bottom: 1.19rem;
      padding: 2.125rem 0 1.25rem 0;
      border-bottom: .063rem solid #EEF4FA;
      cursor: default;
    }
    li:not(:first-child):hover{
        background: rgba(36, 108, 183, 0.1);
        border-radius: 0.19rem;
        padding-left: .75rem;
        margin-left: -.75rem;
    }
  }
}
.region_scroll::-webkit-scrollbar { width: .125rem;}
.region_scroll::-webkit-scrollbar-track {
    -webkit-border-radius: .19rem;
    border-radius: .19rem;
    background:#EEF4FA;
}
.region_scroll::-webkit-scrollbar-thumb {
    -webkit-border-radius: .19rem;
    border-radius: .19rem;
    background:#5A79A8;
}
.region_scroll::-webkit-scrollbar-thumb:window-inactive { background:#5A79A8;}

.fade-enter-active, .component-fade-leave-active {
  transition: all .35s;
}
.fade-enter, .component-fade-leave-to{
  opacity: 0;
  transform: translateY(-1rem);
}

@media screen and (max-width: 768px){
  .custom-select__header{
    padding: 0.4rem 1.25rem;
  }
}
</style>
