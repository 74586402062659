<template>
  <!--
  list - объект, список элементов, должен содержать обязательный ключ title. Остальные ключи могут быть любые
  title - название элемента списка
  placeholder - необязательный, обычный placeholder
  castom - это кастомный элемент, для фильтрации. Например когда в фильтре помимо занчений из списка нужно показать Все. необязательный
  -->
  <div tabindex="1" @blur="CloseDropDown" class="custom-select">
    <div @click="ChangeStatusCustomSelect" class="custom-select__header">

      <p v-if="title" v-trim="1">{{ title }}</p>
      <p v-else v-trim="1">{{ placeholder ? placeholder : title }}</p>

      <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="11.1549" y="5.05664" width="7.00006" height="2.00002" rx="1" transform="rotate(134.83 11.1549 5.05664)" fill="#5A79A8"/>
        <rect x="2.66565" y="3.66797" width="7.00006" height="2.00002" rx="1" transform="rotate(44.8295 2.66565 3.66797)" fill="#5A79A8"/>
      </svg>

    </div>
    <transition name="fade">
        <div v-show="statusCustomSelect" class="custom-select__main">
        <!-- <p v-if="castom" @click="changeTitle(castom)">{{ castom }}</p> -->
        <!-- <p v-for="(item,i) in list" :key="`CustomSelect${i}`" @click="changeTitle(item.title, i)">{{ item.title }}</p> -->
            <div class="region_block">
                <div class="region_block__icon" v-for="(item, index) in list" :key="`topic${index}`">
                    <figure :style="`background: url('${'/assets/icons/small_icon/' + item.icon}') center / contain no-repeat;`"></figure>
                    <p  @click="changeTitle(item.title)">{{ item.title }}</p>
                </div>
            </div>
        </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'SelectTopic',
  props: {
    list: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String
    },
    castom: {
      type: String
    },
    // title - передаем текст который нужно вывести заголовке в селект
    title: {
      type: String,
      required: false,
      default: ''
    },
    // key_name - передается в паре с title, это ключ тайтла. например {name: 'заголовок в селекте'}
    // То в title передаем 'заголовок в селекте', а в key_name передаем name
    // Это для того чтобы в полученном массиве сразу по фильтру найти строку с тайтлом и вернуть этот объект куда нужно уже
    // если запись по тайтлу в массие не найдена то вернется []
    key_name: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      statusCustomSelect: false,
      address: ''
    }
  },
  mounted () {
    if (this.title && this.key_name) {
      const value = this.list.filter(el => {
        return String(el[`${this.key_name}`]) === this.title
      })
      this.$emit('click', value) // ну или что там нужно..
    }
  },
  methods: {
    ChangeStatusCustomSelect () {
      this.statusCustomSelect = !this.statusCustomSelect
    },
    CloseDropDown () {
      this.statusCustomSelect = false
    },
    changeTitle (value, key) {
      this.CloseDropDown()
      this.$emit('InputsContent', key !== undefined ? this.list[key] : '')
      this.title = value
    }
  }
}
</script>

<style scoped lang="scss">
.custom-select {
  position: relative;
  text-align: left;

  background: #EEF4FA;
  border-radius: .19rem;

  &__header {
    font-size: 0.875rem;
    line-height: 157%;
    color: #003E78;
    background: #EEF4FA;
    background-size: 1.25rem;
    border-radius: .19rem;
    padding: 0.56rem 1.25rem;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    svg {
      min-width: 1rem;
      min-height: 1rem;
    }
  }

  &__main {
    position: absolute;
    top: 140%;
    z-index: 99999;
    background: #fff;
    box-sizing: border-box;
    width: 59.5rem;
    height: 25.563rem;
    text-align: left;
    padding: 1.56rem 0 1.56rem 1rem;

    filter: drop-shadow(0px 33px 88px rgba(37, 70, 103, 0.15));
  }
}

.region_block{
    height: 100%;
    display: flex;
    flex-flow: column wrap;

    &__icon{
        display: flex;
        align-items: center;
        width: calc(50% - 2rem);
        margin-right: 2rem;
        padding-left: .75rem;

        figure{
            width: 1.25rem;
            height: 1.25rem;
            margin-right: .88rem;
        }
        p{
            font-size: .88rem;
            line-height: 2.8rem;
            color: #003E78;
            cursor: pointer;
        }
    }
    &__icon:hover{
        background: rgba(36, 108, 183, 0.1);
        border-radius: 0.19rem;
    }
}

.fade-enter-active, .component-fade-leave-active {
  transition: all .35s;
}
.fade-enter, .component-fade-leave-to{
  opacity: 0;
  transform: translateY(-1rem);
}

@media screen and (max-width: 768px){
  .custom-select__header{
    padding: 0.4rem 1.25rem;
  }
}
</style>
