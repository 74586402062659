<template>
<div>
  <ul class="filter-chambers" v-show="!showBlockSelect">
    <li>
      <!-- <input class='input' type="text" v-model="fio" placeholder="Поиск" @keyup.enter="apiMemberList()"> -->
      <select-appeal :key="`city${count}`" class="select city_icon" :list='getCity' @InputsContent="setCity" placeholder="Город, адрес, метро..."/>
    </li>
    <li>
      <p @click="showFilter = !showFilter" :class="{'transform-button': showFilter}">
        <span v-if="!showFilter">Открыть</span>
        <span v-else>Свернуть</span> фильтры</p>
      <button type="button" @click="$store.dispatch('setView', 'btnBlock')" class="btn__icon btnBlock" :class="{'active' : getListView !== 'btnBlock'}"></button>
      <button type="button" @click="$store.dispatch('setView', 'btnList')" class="btn__icon btnList" :class="{'active' : getListView !== 'btnList'}"></button>
      <button type="button" @click="$store.dispatch('setView', 'btnMap')" class="btn__icon btnMap" :class="{'active' : getListView !== 'btnMap'}"></button>
    </li>
    <li :class="{'display_filter': showFilter===true}">
      <select-topic :key="`inclusion${count}`" class="select" :list='getTheme' @InputsContent="setTheme" placeholder="Тема обращений"/>
      <custom-select :key="`unit_commis${count}`" class="select" :list='getCommissions' @InputsContent="setCommissions" placeholder="Комиссии"/>
      <custom-select :key="`unit_status${count}`" class="select" :list='getStatus' @InputsContent="setStatus" placeholder="Статус обращений"/>
      <button type="button" @click="resetFilters()">Сбросить фильтры</button>
    </li>
  </ul>
  <!--  -->
  <!--  -->
  <!--  -->
  <ul class="filter-chambers" v-show="showBlockSelect" :class="{'logon_icon': showBlockSelect}">
    <li>
      <!-- <input class='input' type="text" v-model="fio" placeholder="Поиск" @keyup.enter="apiMemberList()"> -->
      <select-appeal :key="`city${count}`" class="select__login" :list='getCity' @InputsContent="setCity" placeholder="Город, адрес, метро..." />
      <select-topic :key="`inclusion${count}`" class="select__login" :list='getTheme' @InputsContent="setTheme" placeholder="Тема обращений"/>
      <custom-select :key="`unit_commis${count}`" class="select__login" :list='getCommissions' @InputsContent="setCommissions" placeholder="Комиссии"/>
      <custom-select :key="`unit_status${count}`" class="select__login" :list='getStatus' @InputsContent="setStatus" placeholder="Статус обращений"/>
      <router-link to="appeal_forms" class="button_link"> Подать обращение </router-link>
<!--      <a href="https://oprflk.dev.devfbit.ru"  class="button_link"> Подать обращение </a>-->
    </li>
    <li>
      <button type="button" @click="$store.dispatch('setView', 'btnBlock')" class="btn__icon btnBlock" :class="{'active' : getListView !== 'btnBlock'}"></button>
      <button type="button" @click="$store.dispatch('setView', 'btnList')" class="btn__icon btnList" :class="{'active' : getListView !== 'btnList'}"></button>
      <button type="button" @click="$store.dispatch('setView', 'btnMap')" class="btn__icon btnMap" :class="{'active' : getListView !== 'btnMap'}"></button>
    </li>
  </ul>
  </div>
</template>

<script>
import CustomSelect from '@/components/forms/CustomSelect'
import SelectAppeal from '@/components/appeal/SelectAppeal'
import SelectTopic from '@/components/appeal/SelectTopic'

export default {
  name: 'FilterAppeal',
  props: {
    showBlockSelect: {
      type: Boolean
    }
  },

  data () {
    return {
      count: 0,
      showFilter: false,
      getCity: {
        А: [
          'Алтайский край',
          'Амурская область',
          'Архангельская область',
          'Астраханская область',
          'Архангельская область',
          'Астраханская область',
          'Архангельская область',
          'Астраханская область'
        ],
        Б: [
          'Белгородская область',
          'Брянская область'
        ],
        Р: [
          'Республика Адыгея (Адыгея)',
          'Республика Алтай',
          'Республика Башкортостан',
          'Республика Бурятия',
          'Республика Дагестан',
          'Республика Адыгея (Адыгея)',
          'Республика Алтай',
          'Республика Башкортостан',
          'Республика Бурятия',
          'Республика Дагестан',
          'Республика Адыгея (Адыгея)',
          'Республика Алтай'
        ],
        Ч: [
          'Челябинская область',
          'Чеченская Республика',
          'Чувашская Республика - Чувашия',
          'Чукотский автономный округ'
        ],
        Я: [
          'Ямало-Ненецкий автономный округ',
          'Ярославская область'
        ]
      },
      getTheme: [
        {
          title: 'Здравоохранение',
          icon: 'health_small.svg'
        },
        {
          title: 'Семья, материнство, детство',
          icon: 'family_small.svg'
        },
        {
          title: 'Экология',
          icon: 'eco_small.svg'
        },
        {
          title: 'ЖКХ, строительство и дороги',
          icon: 'hcs_small.svg'
        },
        {
          title: 'Образование и наука',
          icon: 'education_small.svg'
        },
        {
          title: 'Экономика и предпринимательство',
          icon: 'economics_small.svg'
        },
        {
          title: 'Социальная политика',
          icon: 'social_policy_small.svg'
        },
        {
          title: 'Некоммерческие организации',
          icon: 'nonprofit_organization_small.svg'
        },
        {
          title: 'Агропромышленный комплекс',
          icon: 'agribusiness_small.svg'
        },
        {
          title: 'Физкультура и здоровый образ жизни',
          icon: 'physical_education_small.svg'
        },
        {
          title: 'Общественный контроль',
          icon: 'public_control_small.svg'
        },
        {
          title: 'Территориальное развитие',
          icon: 'territorial_development_small.svg'
        },
        {
          title: 'Благотворительность',
          icon: 'charity_small.svg'
        },
        {
          title: 'Молодежная политика и волонтерство',
          icon: 'volunteering_small.svg'
        },
        {
          title: 'Общественная дипломатия',
          icon: 'public_diplomacy_small.svg'
        }
      ],
      getCommissions: [
        {
          title: 'Все'
        }
      ],
      getStatus: [
        {
          title: 'Все'
        },
        {
          title: 'В работе'
        }
      ]
    }
  },

  mounted () {
    // this.$store.dispatch('apiInclusionList')
    // this.$store.dispatch('apiUnitTypeList')
  },

  methods: {
    resetFilters () {
      this.$store.commit('setCity', '')
      this.$store.commit('setTheme', '')
      this.$store.commit('setCommissions', '')
      this.$store.commit('setStatus', '')
      this.count += 1
    },
    setCity (value) {
      this.$store.commit('setCity', value)
    },
    setTheme (value) {
      this.$store.commit('setTheme', value)
    },
    setCommissions (value) {
      this.$store.commit('setCommissions', value)
    },
    setStatus (value) {
      this.$store.commit('setStatus', value)
    }
  },

  computed: {
    getListView () {
      return this.$store.getters.getListView
    }
    // getCity () {
    //   return this.$store.getters.getCity
    // },
    // getTheme () {
    //   return this.$store.getters.getTheme
    // }
    // getCommissions () {
    //   return this.$store.getters.getCommissions
    // },
    // getStatus () {
    //   return this.$store.getters.getStatus
    // }

  },

  components: {
    'custom-select': CustomSelect,
    'select-appeal': SelectAppeal,
    SelectTopic
  }
}
</script>

<style scoped lang="scss">
.filter-chambers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem 0;

  li {
    display: flex;
    position: relative;
    margin-right: 0;
    align-items: center;

  }
  li:nth-child(1) {
    margin-right: 0;
    // width: 19.375rem;
    button {
      width: 2.18rem;
      height: 2.45rem;
      border: none;
      margin: auto .94rem auto 0;
      background-color: #EEF4FA;
      border-radius: .19rem;
    }
  }
  li:nth-child(3) {
    // flex: 1;
    width: 100%;
    display: none;
    // order: 3;

    // .select {
    //   width: 13.89rem;
    //   margin-right: 0.625rem;
    // }

    button {
      position: relative;
      left: 0;
      top: 0;
      font-size: 0.75rem;
      margin-left: auto;
      padding-left: 1rem;
      border: none;
      background: inherit;
      color: #003E78;
      opacity: 0.6;
      cursor: pointer;
    }
  }
  .display_filter{
      display: flex !important;
  }
  li:nth-child(2){
    margin-bottom: 1.375rem;

    p{
        display: block;
        font-size: .75rem;
        line-height: 1rem;
        color: #246CB7;
        margin-right: .935rem;
        padding-left: 1.43rem;
        cursor: pointer;
    }
    p:before{
        content: '';
        width: 1.25rem;
        height: 1rem;
        background: url('../../assets/icons/arrow.svg') center center no-repeat;
        padding: 0.5rem;
        margin-right: .19rem;
        position: absolute;
        left: 0;
    }
    .transform-button:before{
        transform: rotate(180deg);
        padding: 0.6rem;
    }
  }
}
.btnMap{
    background: url('../../assets/icons/map.svg');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
}
.city_icon{
    margin-bottom: 1.38rem;
}
.select {
    width: 19.38rem;
    margin-right: .75rem;
}
.select__login{
    width: 13.75rem;
    margin-right: .94rem;
}
.button_link {
    font-weight: bold;
    font-size: .88rem;
    line-height: 1.25rem;
    text-align: center;
    color: #FFFFFF;
    background: #EB494E;
    border-radius: .19rem;
    padding: .56rem 2.125rem .56rem 2.125rem;// .44
}
.logon_icon{
    li:nth-child(2){
        margin-bottom: 0;
    }
}
@media screen and (max-width: 420px){
  .filter-chambers{
    li:nth-child(1){
      width: 100%;
    }
    li:nth-child(2){
      width: 100%;
      flex-direction: column;
      .select{
        width: 100%;
        margin-right: 0;
        margin-bottom: .94rem;
      }
      button{
        margin: 0;
        opacity: 1;
        padding: 0;
        color: #246CB7;
      }
    }
    li:nth-child(3){
      width: 100%;
      justify-content: flex-end;
      p{
        position: absolute;
        left: 0;
      }
    }
  }
}
</style>
